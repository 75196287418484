import { Scope } from "@marketpartner/backend-api"
import { DialogTitle } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { DynamicEmailTemplateForm } from "src/email/templates/dynamic-templates/DynamicEmailTemplateForm"


export type CreateDynamicEmailTemplateDialogProps = LockableDialogProps

const CreateDynamicEmailTemplateDialog: FC<CreateDynamicEmailTemplateDialogProps> = ({
    ...props
}) => {
    const client = useClient()!

    const createTemplateMutation = backend.emailTemplates.useCreateDynamicEmailTemplate(withNotification({
        errorMessage: "Error creating dynamic email template"
    }))

    return <LockableDialog
        maxWidth="md"
        fullWidth
        {...props}
    >
        <DialogTitle>Configure new email template</DialogTitle>
        <DynamicEmailTemplateForm
            actionName="Create"
            onSubmit={template => createTemplateMutation.mutateAsync([client.id, template])}
        />
    </LockableDialog>
}

export const useCreateDynamicEmailTemplateDialog = createPopupHook({
    element: CreateDynamicEmailTemplateDialog,
    scope: Scope.Client,
})