import { EmailTemplateType, Scope } from "@marketpartner/backend-api";
import { filterType } from "@marketpartner/mp-common";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DialogTitle, Typography } from "@mui/material";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { EditableDialogList } from "src/common/dialogs/EditableDialogList";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { useAddDynamicEmailTemplateMenu } from "src/email/templates/dynamic-templates/AddDynamicEmailTemplateMenu";
import { useDeleteDynamicEmailTemplateDialog } from "src/email/templates/dynamic-templates/DeleteDynamicEmailTemplateDialog";
import { useEditDynamicEmailTemplateDialog } from "src/email/templates/dynamic-templates/EditDynamicEmailTemplateDialog";


export type DynamicEmailTemplatesProps = LockableDialogProps

const DynamicEmailTemplates: FC<DynamicEmailTemplatesProps> = ({
    ...props
}) => {
    const client = useClient()!

    const templatesQuery = backend.emailTemplates.useGetEmailTemplatesForClient([client.id], {
        select: templates => filterType(templates, EmailTemplateType.Dynamic as const)
    })
    const addMenu = useAddDynamicEmailTemplateMenu()
    const editDialog = useEditDynamicEmailTemplateDialog()
    const deleteDialog = useDeleteDynamicEmailTemplateDialog()

    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Configure dynamic email templates</DialogTitle>
        <EditableDialogList
            itemsQuery={templatesQuery}
            getPrimaryText={template => <Typography
                children={template.name}
                color={template.hidden ? "text.disabled" : "text.primary"}
            />}
            getSecondaryText={template => template.id}
            getIcon={template => template.hidden ?
                <VisibilityOff color="disabled" /> :
                <Visibility />
            }
            addProps={{
                onClickAdd: e => addMenu.open({
                    anchorEl: e.currentTarget,
                })
            }}
            contextMenuProps={template => ({
                onClickEdit: () => editDialog.open({ template }),
                onClickDelete: () => deleteDialog.open({ template }),
            })}
        />
    </LockableDialog>
}

export const useDynamicEmailTemplatesDialog = createPopupHook({
    element: DynamicEmailTemplates,
    scope: Scope.Client,
})