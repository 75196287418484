import { ContentTypeId, contentTypeLookup } from "@marketpartner/backend-api"
import { Description, Link, PlayArrow, Business, CalendarViewDay, Wallpaper, Groups, ViewModule, FormatSize, Assignment, Article, Square, AvTimer, Try, ArtTrack } from "@mui/icons-material"
import { SvgIconProps } from "@mui/material"
import { cloneElement, FC, ReactElement } from "react"

const getTypeIcon = contentTypeLookup<ReactElement<SvgIconProps>>({
    "AgendaModule": <CalendarViewDay />,
    "Company": <Business />,
    "ContentEntitiesModule": <Description />,
    "CountdownClockModule": <AvTimer />,
    "Custom": <Description />,
    "EventTile": <Square />,
    "EventTilesModule": <ViewModule />,
    "HeroModule": <Wallpaper />,
    "HighlightsModule": <Try />,
    "InfoBannerModule": <ArtTrack />,
    "Link": <Link />,
    "MarkdownModule": <FormatSize />,
    "Page": <Article />,
    "Pdf": <Description />,
    "PublicRegistrationsModule": <Groups />,
    "RegistrationModule": <Assignment />,
    "RegAndPaymentModule": <Assignment />,
    "RemoteHostedPdf": <Description />,
    "RemoteHostedVideo": <PlayArrow />,
    "VideoLink": <PlayArrow />,
    "VimeoVideo": <PlayArrow />,
})

export type ContentTypeIconProps = Partial<SvgIconProps> & {
    typeId?: ContentTypeId
}

export const ContentTypeIcon: FC<ContentTypeIconProps> = ({
    typeId = "Custom:",
    ...props
}) => cloneElement(getTypeIcon(typeId), props)