import { ConfiguredEmailTemplate, Permission } from "@marketpartner/backend-api"
import { Edit } from "@mui/icons-material"
import { IconButton, Skeleton, Stack, SxProps, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { useEditConfiguredTemplateDialog } from "src/email/templates/configured-templates/EditConfiguredEmailTemplateDialog"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { NameAndSubtitle } from "src/registrations/NameAndSubtitle"

export type EditableConfiguredEmailTemplateProps = {
    configuredTemplate: ConfiguredEmailTemplate | null
    onChange: (template: ConfiguredEmailTemplate | null) => Promise<unknown> | void
    editLabel: string
    label?: string
    sx?: SxProps
}

export const EditableConfiguredEmailTemplate: FC<EditableConfiguredEmailTemplateProps> = ({
    configuredTemplate,
    onChange,
    editLabel,
    label,
    sx
}) => {
    const client = useClient()!
    const event = useEvent()!

    const templateQuery = backend.emailTemplates.useGetEmailTemplatesForClient([client.id], {
        select: templates => templates.find(t => t.id === configuredTemplate?.id) ?? null
    })

    const editDialog = useEditConfiguredTemplateDialog()

    const getTemplateText = () => {
        if (!configuredTemplate) {
            return <Typography variant="body2" color="text.secondary">None</Typography>
        }
        if (templateQuery.isPending || templateQuery.isError) {
            return <Skeleton width={200} />
        }
        if (templateQuery.data === null) {
            return <Typography variant="body2" color="error">{configuredTemplate.id} (not found)</Typography>
        }
        return <NameAndSubtitle
            name={templateQuery.data.name}
            subtitle={templateQuery.data.id}
        />
    }

    return <Bar spacing={1} sx={sx}>
        <Stack>
            {label && <b>{label}</b>}
            {getTemplateText()}
        </Stack>
        {ifHas(Permission.EditEventDetails, client.id, event.id, <IconButton
            children={<Edit />}
            color="primary"
            onClick={() => editDialog.open({
                configuredTemplate,
                onChange,
                editLabel,
            })}
        />)}
    </Bar>
}