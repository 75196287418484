import { entityContentTypeLookup } from "@marketpartner/backend-api";

export const getContentSecondaryText = entityContentTypeLookup<string[]>({
    AgendaModule: module => ['Agenda Module'],
    Company: company => [company.fields.website_url ?? ""],
    ContentEntitiesModule: () => [],
    CountdownClockModule: module => ['Countdown Clock Module', module.fields.countdown_clock_time],
    Custom: () => [],
    EventTile: eventTile => [eventTile.fields.date ?? ""],
    EventTilesModule: () => ['Event Tiles Module'],
    HeroModule: () => ['Hero'],
    HighlightsModule: module => ['Highlights Module'],
    InfoBannerModule: module => ['Info Banner Module', module.fields.info_banner_text ?? ""],
    Link: link => [link.fields.url],
    MarkdownModule: () => ['Markdown Module'],
    Page: page => ['Page', page.fields.path],
    Pdf: pdf => [pdf.fields.file.absoluteUrl],
    PublicRegistrationsModule: module => ["Public Registrations Module"],
    RegistrationModule: module => ["Registration Module"],
    RegAndPaymentModule: module => ["Reg and Payment Module"],
    RemoteHostedPdf: pdf => [pdf.fields.url],
    RemoteHostedVideo: video => [video.fields.src],
    VideoLink: video => [video.fields.url],
    VimeoVideo: video => [video.fields.url],
})