import { BooleanExpression, BooleanExpressionGroup } from "@marketpartner/backend-api"
import { ReactNode } from "react"
import { BooleanConditionPropertiesLookup, TypedBooleanCondition } from "src/common/boolean-conditions/boolean-condition-properties"
import { BooleanCondition } from "src/common/boolean-conditions/BooleanCondition"
import { BooleanExpressionContainer } from "src/common/boolean-conditions/BooleanExpressionContainer"
import { EditableBooleanExpressionGroup } from "src/common/boolean-conditions/EditableBooleanExpressionGroup"


export type EditableBooleanExpressionProps<Condition extends TypedBooleanCondition> = {
    expression: BooleanExpression<Condition>
    onChange: (expression: BooleanExpression<Condition>) => void
    onDelete: () => void
    conditionPropertiesLookup: BooleanConditionPropertiesLookup<Condition>
    getEvaluatedExpression?: (expression: BooleanExpression<Condition>) => ReactNode
}

export const EditableBooleanExpression = <Condition extends TypedBooleanCondition>({
    expression,
    onChange,
    onDelete,
    conditionPropertiesLookup,
    getEvaluatedExpression,
}: EditableBooleanExpressionProps<Condition>) => {
    if (expression.type === "Group") {
        return <EditableBooleanExpressionGroup
            group={expression as BooleanExpressionGroup<Condition>}
            onChangeGroup={onChange}
            onDelete={onDelete}
            conditionPropertiesLookup={conditionPropertiesLookup}
        />
    }
    return <BooleanExpressionContainer expression={expression}>
        <BooleanCondition
            condition={expression}
            properties={(conditionPropertiesLookup as any)[expression.type]}
            onChange={onChange}
            onDelete={onDelete}
            getEvaluatedExpression={getEvaluatedExpression}
        />
    </BooleanExpressionContainer>
}