import { ConfiguredEmailTemplate, EventDetails, isRegistrationEmailType, RegistrationEmailType, TriggeredEmailKey } from "@marketpartner/backend-api"
import { List, ListItemButton, ListItemIcon, ListItemText, Radio, SxProps } from "@mui/material"
import { FC, Fragment, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Loading } from "src/common/loading/Loading"
import { emailTriggerLabels } from "src/email/triggers/EmailTriggersGrid"


export type ConfiguredTemplateSelectorProps = {
    event: EventDetails
    onSelectTemplate: (template: ConfiguredEmailTemplate) => void
    sx?: SxProps
}

type SelectedTemplateKey =
    `event-${TriggeredEmailKey}` |
    `category-override-${string}-${TriggeredEmailKey}`

export const ConfiguredTemplateSelector: FC<ConfiguredTemplateSelectorProps> = ({
    event,
    onSelectTemplate,
    sx
}) => {
    const client = useClient()!
    const [selectedTemplateKey, setSelectedTemplateKey] = useState<SelectedTemplateKey | null>(null)

    const categoriesQuery = backend.registrationCategories.useGetAll([client.id, event.id])

    type ConfiguredTemplateOptionProps = {
        templateKey: SelectedTemplateKey
        template: ConfiguredEmailTemplate | null
        label: string
        indent?: boolean
    }

    const ConfiguredTemplateOption: FC<ConfiguredTemplateOptionProps> = ({
        templateKey,
        template,
        label,
        indent,
    }) => {
        return <ListItemButton
            onClick={() => {
                template && onSelectTemplate(template!)
                template && setSelectedTemplateKey(templateKey)
            }}
            disabled={!template}
            sx={{ pl: indent ? 4 : 2 }}
            dense
        >
            <ListItemIcon>
                <Radio
                    checked={selectedTemplateKey === templateKey}
                    edge="start"
                    disableRipple
                />
                <ListItemText
                    primary={label}
                    secondary={template?.id ?? "None"}
                />
            </ListItemIcon>
        </ListItemButton>
    }

    return <Loading request={categoriesQuery} sizing="fit-content" render={categories => <List sx={sx}>
        {Object.values(TriggeredEmailKey).map(key => <Fragment key={key}>
            <ConfiguredTemplateOption
                key={`event-${key}`}
                templateKey={`event-${key}`}
                template={event.triggeredEmailTemplates[key]}
                label={emailTriggerLabels[key]}
            />
            {categories.map(category => {
                if (!isRegistrationEmailType(key)) {
                    return null
                }
                const template = category.triggeredEmailTemplateOverrides[key as RegistrationEmailType]
                if (!template || template.type === "DoNotSend") {
                    return null
                }
                return <ConfiguredTemplateOption
                    key={`category-override-${category.id}-${key}`}
                    templateKey={`category-override-${category.id}-${key}`}
                    template={template}
                    label={`${category.name} ${emailTriggerLabels[key]}`}
                    indent
                />
            })}
        </Fragment>)}
    </List>} />
}

