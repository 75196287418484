import { DynamicEmailTemplate, DynamicEmailTemplateId, FormElementConfig, StaticEmailTemplateId } from "@marketpartner/backend-api"
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { IdField } from "src/common/form-inputs/IdField"
import { StaticEmailTemplateSelector } from "src/email/templates/StaticEmailTemplateSelector"
import { EditableFormElements } from "src/forms/elements/EditableFormElements"

export type DynamicEmailTemplateFormData = {
    id: DynamicEmailTemplateId
    name: string
    staticTemplateId: StaticEmailTemplateId
    hidden: boolean
    templateFieldElements: FormElementConfig[]
}

export type DynamicEmailTemplateFormProps = ExtendableDialogFormProps<DynamicEmailTemplateFormData> & {
    template?: DynamicEmailTemplate
}

export const DynamicEmailTemplateForm: FC<DynamicEmailTemplateFormProps> = ({
    template,
    ...props
}) => {
    const [id, setId] = useState(template?.id?.replace(/^Dynamic:/, "") ?? "")
    const [name, setName] = useState(template?.name ?? "")
    const [staticTemplateId, setStaticTemplateId] = useState(template?.staticTemplateId ?? null)
    const [hidden, setHidden] = useState(template?.hidden ?? false)
    const [templateFieldElements, setTemplateFieldElements] = useState(template?.templateFieldElements ?? [])

    return <DialogForm
        formData={{
            id: `Dynamic:${id}`,
            name,
            staticTemplateId: staticTemplateId!,
            hidden,
            templateFieldElements,
        }}
        isValid={Boolean(id && name && staticTemplateId)}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    required
                    fullWidth
                />
            </Grid>
            {!template && <Grid item xs={12}>
                <IdField
                    idValue={id}
                    nameValue={name}
                    onChangeId={setId}
                    maxLength={450}
                    inputProps={{
                        startAdornment: <Typography color="text.secondary">Dynamic:</Typography>
                    }}
                />
            </Grid>}
            <Grid item xs={12}>
                <StaticEmailTemplateSelector
                    label="Mailjet template"
                    selectedId={staticTemplateId}
                    onSelectId={setStaticTemplateId}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    label="Hidden"
                    control={<Checkbox
                        checked={hidden}
                        onChange={e => setHidden(e.target.checked)}
                    />}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Template fields</Typography>
                <EditableFormElements
                    elements={templateFieldElements}
                    onChangeElements={setTemplateFieldElements}
                    defaultSourceType={"DisableCopy"}
                    canEdit
                    sizing="fit-content"
                />
            </Grid>
        </Grid>
    </DialogForm>
}