import { SelectConfig } from "@marketpartner/backend-api"
import { Grid, TextField } from "@mui/material"
import { ChoicesConfig } from "src/forms/elements/types/ChoicesConfig"
import { FormElementConfigComponent } from "src/forms/elements/types/formElementConfigTypes"
import { StandardFields } from "src/forms/elements/types/StandardFields"

export const SelectConfigComponent: FormElementConfigComponent<SelectConfig> = ({
    config,
    updateConfigProperty
}) => {

    const emptyOption = config.choices.find(c => c.value === '')
    const errorMessage =
        (config.choices.length === 0) ? 'Selects must have at least one option' :
            (!config.required && !emptyOption) ? 'Optional selects must have an empty option' :
                (!config.required && emptyOption?.disabled) ? 'The empty option must not be disabled' :
                    undefined

    return <>
        <StandardFields config={config} updateConfigProperty={updateConfigProperty} />
        <Grid item xs={12}>
            <TextField
                label="Default value"
                fullWidth
                value={config.defaultValue}
                onChange={(e) => updateConfigProperty('defaultValue', e.target.value)}
            />
        </Grid>
        <Grid item xs={12}>
            <ChoicesConfig
                config={config}
                updateConfigProperty={updateConfigProperty}
                headerText="Select options"
                errorMessage={errorMessage}
            />
        </Grid>
    </>
}