import { AgendaItemType, DayAgenda, Permission, StructuredAgenda, StructuredAgendaItem } from "@marketpartner/backend-api"
import { Add } from "@mui/icons-material"
import { Button, Divider, IconButton, Stack, SxProps, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { FC, Fragment } from "react"
import { useClient } from "src/clients/client-context"
import { center } from "src/common/styles"
import { DayHeading } from "src/common/time/DayHeading"
import { useAddAgendaItemMenu } from "src/content/agendas/AddAgendaItemMenu"
import { EditableBreakout } from "src/content/agendas/breakouts/EditableBreakout"
import { EditableAgendaHeading } from "src/content/agendas/headings/EditableAgendaHeading"
import { EditableSession } from "src/content/agendas/sessions/EditableSession"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"


export type EditableAgendaProps = {
    agenda: StructuredAgenda
    sx?: SxProps
}

export const EditableAgenda: FC<EditableAgendaProps> = ({
    agenda,
    sx,
}) => {
    if (agenda.byDay.length === 0) {
        return <EmptyAgenda agendaId={agenda.id} />
    }

    return <Stack sx={{
        px: 1,
        maxWidth: 1000,
        ...sx,
    }}>
        {agenda.byDay.map((dayAgenda) =>
            <EditableDayAgenda key={dayAgenda.day.toISODate()} agendaId={agenda.id} dayAgenda={dayAgenda} />
        )}
    </Stack>
}

type EditableDayAgendaProps = {
    agendaId: string
    dayAgenda: DayAgenda<StructuredAgendaItem>
}

const EditableDayAgenda: FC<EditableDayAgendaProps> = ({
    agendaId,
    dayAgenda,
}) => {
    const addMenu = useAddAgendaItemMenu()

    return <Stack sx={{ my: 2 }}>
        <DayHeading day={dayAgenda.day} />
        <Stack sx={{ mt: 4 }}>
            {dayAgenda.items.map((item, idx) => <Fragment key={item.id}>
                {idx !== 0 && <Divider />}
                <Item item={item} />
            </Fragment>)}
            <IconButton
                onClick={e => addMenu.open({
                    agendaId,
                    anchorEl: e.currentTarget,
                    suggestedStart: endOf(dayAgenda.items),
                })}
                sx={{ alignSelf: "center" }}
            ><Add /></IconButton>
        </Stack>
    </Stack>
}

type EditableAgendaItemProps = {
    item: StructuredAgendaItem
}

const Item: FC<EditableAgendaItemProps> = ({
    item
}) => {
    switch (item.type) {
        case AgendaItemType.Session: return <EditableSession session={item} />
        case AgendaItemType.Heading: return <EditableAgendaHeading heading={item} />
        case AgendaItemType.Breakout: return <EditableBreakout breakout={item} />
    }
}

type EmptyAgendaProps = {
    agendaId: string
}

const EmptyAgenda: FC<EmptyAgendaProps> = ({
    agendaId,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const addMenu = useAddAgendaItemMenu()
    const suggestedStart = event.startTime.setZone(event.timezone)

    return <Stack spacing={2} sx={{ alignItems: "center", ...center }}>
        <Typography>
            Agenda is empty
        </Typography>
        {ifHas(Permission.EditEventContent, client.id, event.id, <Button
            onClick={e => addMenu.open({
                agendaId,
                suggestedStart,
                anchorEl: e.currentTarget,
            })}
            startIcon={<Add />}
        >
            Add item
        </Button>)}
    </Stack>
}

const endOf = (
    items: StructuredAgendaItem[]
): DateTime => DateTime.max(
    ...items.map(it => "end" in it ? it.end : it.start)
)