import { ScheduledJobExecutionState, ScheduledJobState } from "@marketpartner/backend-api"
import { Spinning } from "@marketpartner/mp-common-mui"
import { Check, Clear, Pending, Refresh, Schedule } from "@mui/icons-material"
import { SxProps, Typography } from "@mui/material"
import { FC, ReactNode } from "react"
import { Bar } from "src/common/layout/Bar"


export type ScheduledJobStatusProps = {
    job: ScheduledJobState | null
    sx?: SxProps
}

const stateIcons: Record<ScheduledJobExecutionState | "Scheduled", ReactNode> = {
    Scheduled: <Schedule />,
    Queued: <Pending />,
    Running: <Spinning><Refresh /></Spinning>,
    Success: <Check color="success" />,
    Error: <Clear color="error" />
}

export const ScheduledJobStatus: FC<ScheduledJobStatusProps> = ({
    job,
    sx,
}) => {
    return <Bar
        spacing={0.5}
        sx={{
            "& .hover": job ? {
                cursor: "pointer",
            } : {},
            ...sx
        }}
    >
        {stateIcons[job?.state ?? "Scheduled"]}
        <Typography>{job?.state ?? "Scheduled"}</Typography>
    </Bar>
}