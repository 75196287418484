import { isRegistrationEmailType, Permission, TriggeredEmailKey } from "@marketpartner/backend-api"
import { SxProps } from "@mui/material"
import { GridColDef, GridRowParams, GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { QueryDataGrid } from "src/common/grid/QueryDataGrid"
import { Bar } from "src/common/layout/Bar"
import { ContextMenuButton } from "src/common/menus/ContextMenuButton"
import { withNotification } from "src/common/notifications/with-notification"
import { EditableConfiguredEmailTemplate } from "src/email/templates/configured-templates/EditableConfiguredEmailTemplate"
import { useEmailTriggerContextMenu } from "src/email/triggers/EmailTriggerContextMenu"
import { EmailTriggerOverrides } from "src/email/triggers/EmailTriggerOverrides"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"


export type EmailTriggersGridProps = {
    sx?: SxProps
}

type TriggerRow = {
    key: TriggeredEmailKey
}

export const emailTriggerLabels: Record<TriggeredEmailKey, string> = {
    registrationPendingApproval: "Pending approval",
    registrationApproved: "Approved",
    registrationRejected: "Rejected",
    registrationCancelled: "Cancelled",
    alreadyRegistered: "Already registered",
    resendAccessLink: "Resend access link",

    meetingRequested: "Meeting requested",
    meetingAccepted: "Meeting accepted",
    messageNotification: "Message notification",
}

export const EmailTriggersGrid: FC<EmailTriggersGridProps> = ({
    sx
}) => {

    return <QueryDataGrid
        queryOrRows={Object.values(TriggeredEmailKey).map(key => ({
            key
        }))}
        columns={columns}
        getRowId={getRowId}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        columnVisibilityModel={columnVisibilityModel}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        disableColumnMenu
        disableRowSelectionOnClick
        sx={sx}
    />
}

const getRowId = (row: TriggerRow) => row.key

const getDetailPanelHeight = () => "auto" as const

const getDetailPanelContent = ({ row }: GridRowParams<TriggerRow>) => isRegistrationEmailType(row.key) ?
    <EmailTriggerOverrides triggerKey={row.key} /> :
    undefined

const detailPanelExpandedRowIds = Object.values(TriggeredEmailKey)

const columnVisibilityModel = {
    [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
}

const columns: GridColDef<TriggerRow>[] = [
    {
        field: "key",
        headerName: "Trigger",
        renderCell: ({ row }) => emailTriggerLabels[row.key],
        width: 150,
    },
    {
        field: "template",
        headerName: "Template",
        renderCell: ({ row }) => <EditableConfiguredEventEmailTemplate triggerKey={row.key} />,
        flex: 1,
        sortable: false,
    },
]

type EditableConfiguredEventEmailTemplateProps = {
    triggerKey: TriggeredEmailKey
}

const EditableConfiguredEventEmailTemplate: FC<EditableConfiguredEventEmailTemplateProps> = ({
    triggerKey
}) => {
    const client = useClient()!
    const event = useEvent()!

    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        errorMessage: "Error updating email template"
    }))

    const contextMenu = useEmailTriggerContextMenu()

    return <Bar>
        <EditableConfiguredEmailTemplate
            editLabel={`${emailTriggerLabels[triggerKey]} email template`}
            configuredTemplate={event.triggeredEmailTemplates[triggerKey]}
            onChange={template => updateEventMutation.mutateAsync([client.id, event.id, {
                triggeredEmailTemplates: {
                    [triggerKey]: template,
                }
            }])}
        />
        {isRegistrationEmailType(triggerKey) && ifHas(Permission.EditEventDetails, client.id, event.id,
            <ContextMenuButton onClick={e => contextMenu.open({
                triggerKey,
                anchorEl: e.currentTarget,
            })} />
        )}
    </Bar>
}