import { Permission } from "@marketpartner/backend-api"
import { Cloud, Email, Payment } from "@mui/icons-material"
import { Button, Paper } from "@mui/material"
import { FC } from "react"
import { useNavigate } from "react-router"
import { useClient } from "src/clients/client-context"
import { DeleteClientButton } from "src/clients/DeleteClientButton"
import { EditClientButton } from "src/clients/EditClientButton"
import { MigrationButton } from "src/clients/migration/MigrationButton"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { BasicProperties, BasicProperty } from "src/common/layout/BasicProperties"
import { Page } from "src/common/layout/Page"
import { BasicTabs } from "src/common/routing/BasicTabs"
import { link } from "src/common/routing/routes"
import { EventPartnerInstances } from "src/content/event-partner/instances/EventPartnerInstances"
import { useDynamicEmailTemplatesDialog } from "src/email/templates/dynamic-templates/DynamicEmailTemplatesDialog"
import { Events } from "src/events/Events"
import { useSfmcConfigurationDialog } from "src/integration/salesforce/SfmcConfigurationDialog"
import { ifHas } from "src/login/IfHas"
import { useLoginContext } from "src/login/LoginContext"
import { useStripeInstancesDialog } from "src/payment/stripe/StripeInstancesDialog"

export const ClientPage: FC = () => {
    const client = useClient()!
    const login = useLoginContext()
    const navigate = useNavigate()

    const emailTemplatesDialog = useDynamicEmailTemplatesDialog()
    const sfmcConfigurationDialog = useSfmcConfigurationDialog()
    const stripeInstancesDialog = useStripeInstancesDialog()

    return <Page
        title={client.name}
        back={{
            hideIf: Boolean(login.singleClientId),
        }}
        actions={<>
            {ifHas(Permission.ManageEmailTemplates, client.id, undefined, <Button
                children="Email templates"
                startIcon={<Email />}
                onClick={() => emailTemplatesDialog.open({})}
            />)}
            {ifHas(Permission.AdministerSystem, client.id, undefined, <Button
                children="Salesforce"
                startIcon={<Cloud />}
                onClick={() => sfmcConfigurationDialog.open({})}
            />)}
            {ifHas(Permission.EditClientDetails, client.id, undefined, <Button
                children="Stripe instances"
                startIcon={<Payment />}
                onClick={() => stripeInstancesDialog.open({})}
            />)}
            <EditClientButton client={client} />
            <DeleteClientButton client={client} onDelete={() => navigate(link.toClients())} />
        </>}
        minContentSize={500}
    >
        <BarAndFlex barContent={
            <Paper sx={{ p: 2, pb: 1 }}>
                <BasicProperties>
                    <BasicProperty name="Id" value={client.id} />
                    <BasicProperty name="Name" value={client.name} />
                    {ifHas(Permission.AdministerSystem, client.id, undefined,
                        <BasicProperty name="Latest DB script" value={
                            <MigrationButton client={client} />
                        } />
                    )}
                </BasicProperties>
            </Paper>
        }>
            <Paper sx={{ mt: 2, height: "100%" }}>
                <ClientTabs />
            </Paper>
        </BarAndFlex>
    </Page>

}

const ClientTabs: FC = () => {
    const client = useClient()!

    return <BasicTabs tabs={[{
        label: "Events",
        render: () => <Events />,
    }, {
        label: "Event partner",
        render: () => <EventPartnerInstances />,
        restrictedTo: login => login.hasPermission(Permission.AdministerSystem, client.id)
    }]} />
}
