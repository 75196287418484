import { ConfiguredEmailTemplate, EmailTemplate, EmailTemplateType, Scope } from "@marketpartner/backend-api"
import { MuiFormElements } from "@marketpartner/mp-common-mui"
import { useDynamicFormState } from "@marketpartner/mp-common-react"
import { CopyAll } from "@mui/icons-material"
import { Button, Collapse, DialogTitle, Grid } from "@mui/material"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { asyncBackend } from "src/common/api"
import { DialogForm } from "src/common/dialogs/DialogForm"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { useCopyConfiguredEmailTemplateDialog } from "src/email/templates/configured-templates/CopyConfiguredEmailTemplateDialog"
import { EmailTemplateSelector } from "src/email/templates/EmailTemplateSelector"
import { useEvent } from "src/events/event-context"

export type EditConfiguredEmailTemplateDialogProps = Omit<LockableDialogProps, "onChange"> & EditConfiguredTemplateFormProps & {
    editLabel: string
}

const EditConfiguredEmailTemplateDialog: FC<EditConfiguredEmailTemplateDialogProps> = ({
    configuredTemplate,
    onChange,
    editLabel,
    ...props
}) => {
    return <LockableDialog
        fullWidth
        {...props}
    >
        <DialogTitle>Edit {editLabel.toLocaleLowerCase()}</DialogTitle>
        <EditConfiguredTemplateForm
            configuredTemplate={configuredTemplate}
            onChange={onChange}
        />
    </LockableDialog>
}

export type EditConfiguredTemplateFormProps = {
    configuredTemplate: ConfiguredEmailTemplate | null
    onChange: (template: ConfiguredEmailTemplate | null) => Promise<unknown> | void
}

const EditConfiguredTemplateForm: FC<EditConfiguredTemplateFormProps> = ({
    configuredTemplate,
    onChange,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [templateId, setTemplateId] = useState(configuredTemplate?.id ?? null)
    const [template, setTemplate] = useState<EmailTemplate | null>(null)

    const templateFields = useDynamicFormState({
        elements: template && template.type === EmailTemplateType.Dynamic ? template.templateFieldElements : [],
        requirement: "complete",
        initialValues: configuredTemplate?.type === EmailTemplateType.Dynamic ? configuredTemplate.templateFields : {},
    })

    const copyDialog = useCopyConfiguredEmailTemplateDialog()

    const onCopy = (template: ConfiguredEmailTemplate) => {
        setTemplateId(template.id)
        if (template.type === EmailTemplateType.Dynamic) {
            templateFields.mergeValues(template.templateFields)
        }
    }

    return <DialogForm
        actionName="Save"
        formData={{}}
        onSubmit={() => Promise.resolve(onChange(template === null ? null :
            template.type === EmailTemplateType.Static ? {
                type: EmailTemplateType.Static,
                id: template.id,
            } : {
                type: EmailTemplateType.Dynamic,
                id: template.id,
                templateFields: templateFields.values,
            }
        ))}
        isValid={template?.type !== EmailTemplateType.Dynamic || templateFields.invalidFields.length === 0}
        footerControls={<Button
            children="Copy from event"
            startIcon={<CopyAll />}
            onClick={() => copyDialog.open({
                onCopyTemplate: onCopy,
            })}
            sx={{ display: "none" }}
        />}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <EmailTemplateSelector
                    label="Email template"
                    selectedId={templateId}
                    onSelectId={id => setTemplateId(id)}
                    onSelectLoadedItem={template => setTemplate(template)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={template !== null && template.type === EmailTemplateType.Dynamic}>
                    <MuiFormElements
                        backend={asyncBackend}
                        state={templateFields}
                        uploadPrefix={`clients/${client.id}/events/${event.id}/email-templates`}
                        validationEnabled
                    />
                </Collapse>
            </Grid>
        </Grid>
    </DialogForm>
}

export const useEditConfiguredTemplateDialog = createPopupHook({
    element: EditConfiguredEmailTemplateDialog,
    scope: Scope.Client,
})