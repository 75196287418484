import { BooleanExpression } from "@marketpartner/backend-api"
import { Delete, Edit } from "@mui/icons-material"
import { IconButton, Stack, Typography } from "@mui/material"
import { createElement, ReactNode } from "react"
import { BooleanConditionProperties } from "src/common/boolean-conditions/boolean-condition-properties"
import { useEditConditionDialog } from "src/common/boolean-conditions/EditConditionDialog"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"


export type BooleanConditionProps<Condition> = {
    condition: Condition
    properties: BooleanConditionProperties<Condition>
    onChange: (condition: Condition) => void
    onDelete: () => void
    getEvaluatedExpression?: (expression: BooleanExpression<Condition>) => ReactNode
}

export const BooleanCondition = <Condition,>({
    condition,
    properties,
    onChange,
    onDelete,
    getEvaluatedExpression,
}: BooleanConditionProps<Condition>) => {
    const editConditionDialog = useEditConditionDialog()

    return <Stack>
        <Bar>
            {createElement(properties.iconComponent, { sx: { mr: 1 } })}
            <Typography fontWeight="bold">{properties.getPrimaryText(condition)}</Typography>
            <Spacer />
            <IconButton
                children={<Edit fontSize="small" />}
                onClick={() => editConditionDialog.open({
                    condition,
                    properties,
                    onChange,
                })}
                color="primary"
            />
            <IconButton
                children={<Delete fontSize="small" />}
                onClick={onDelete}
                color="error"
            />
        </Bar>
        {properties.getSecondaryContent?.(condition)}
        {getEvaluatedExpression && <Bar px={1}>
            <Spacer />
            {getEvaluatedExpression(condition)}
        </Bar>}
    </Stack>
}