import { DynamicEmailTemplate, DynamicEmailTemplateId, EmailTemplateType, Scope } from "@marketpartner/backend-api";
import { filterType } from "@marketpartner/mp-common";
import { Collapse, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { ClientSelector } from "src/clients/ClientSelector";
import { backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { IdField } from "src/common/form-inputs/IdField";
import { LoadedAutocomplete } from "src/common/form-inputs/LoadedAutocomplete";
import { withNotification } from "src/common/notifications/with-notification";


export type CopyDynamicEmailTemplateDialogProps = LockableDialogProps

const CopyDynamicEmailTemplateDialog: FC<CopyDynamicEmailTemplateDialogProps> = ({
    ...props
}) => {
    return <LockableDialog
        maxWidth="xs"
        fullWidth
        {...props}
    >
        <DialogTitle>Copy email template</DialogTitle>
        <CopyDynamicEmailTemplateDialogContent />
    </LockableDialog>
}

const CopyDynamicEmailTemplateDialogContent: FC = () => {
    const client = useClient()!

    const [sourceClientId, setSourceClientId] = useState<string | null>(null)
    const [templateId, setTemplateId] = useState<DynamicEmailTemplateId | null>(null)
    const [template, setTemplate] = useState<DynamicEmailTemplate | null>(null)
    const [name, setName] = useState<string>("")
    const [id, setId] = useState<string>("")

    const createMutation = backend.emailTemplates.useCreateDynamicEmailTemplate(withNotification({
        errorMessage: "Error copying email template",
    }))
    const templatesQuery = backend.emailTemplates.useGetEmailTemplatesForClient([sourceClientId!], {
        enabled: Boolean(sourceClientId),
        select: templates => filterType(templates, EmailTemplateType.Dynamic as const),
    })

    const handleSelectClientId = (clientId: string | null) => {
        setSourceClientId(clientId)
        setTemplateId(null)
        setTemplate(null)
    }

    const handleSelectTemplate = (template: DynamicEmailTemplate | null) => {
        setTemplate(template)
        setName(template?.name ?? "")
        setId(template?.id?.replace("Dynamic:", "") ?? "")
    }

    return <DialogForm
        formData={{}}
        actionName="Copy"
        onSubmit={() => createMutation.mutateAsync([client.id, {
            ...template!,
            id: `Dynamic:${id}`,
            name,
        }])}
        isValid={Boolean(sourceClientId && template && name && id)}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ClientSelector
                    clientId={sourceClientId}
                    onSelectClientId={handleSelectClientId}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={Boolean(sourceClientId)}>
                    <LoadedAutocomplete
                        fetchRequest={templatesQuery}
                        selectedId={templateId}
                        onSelectId={setTemplateId}
                        onSelectLoadedItem={handleSelectTemplate}
                        label="Template"
                        fullWidth
                    />
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={Boolean(template)}>
                    <TextField
                        label="Name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                        fullWidth
                    />
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={Boolean(template)}>
                    <IdField
                        idValue={id}
                        nameValue={name}
                        onChangeId={setId}
                        maxLength={450}
                        inputProps={{
                            startAdornment: <Typography color="text.secondary">Dynamic:</Typography>
                        }}
                    />
                </Collapse>
            </Grid>
        </Grid>
    </DialogForm>
}

export const useCopyDynamicEmailTemplateDialog = createPopupHook({
    element: CopyDynamicEmailTemplateDialog,
    scope: Scope.Client,
})