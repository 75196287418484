import { ScheduledEmailBooleanExpression } from "@marketpartner/backend-api"
import { pluralizeCount } from "@marketpartner/mp-common"
import { Skeleton } from "@mui/lab"
import { Box, SxProps } from "@mui/material"
import { FC } from "react"
import { useScheduledEmailRegistrationCount } from "src/email/scheduling/useScheduledEmailRegistrationCount"


export type ScheduledEmailRegistrationCountProps = {
    expression: ScheduledEmailBooleanExpression
    sx?: SxProps
}

export const ScheduledEmailRegistrationCount: FC<ScheduledEmailRegistrationCountProps> = ({
    expression,
    sx,
}) => {
    const registrationCount = useScheduledEmailRegistrationCount(expression)

    return <Box sx={sx}>
        {registrationCount === undefined ?
            <Skeleton width={100} /> :
            pluralizeCount(registrationCount, "registration")
        }
    </Box>
}