import { Permission, ScheduledEmail } from "@marketpartner/backend-api";
import { GridColDef } from "@mui/x-data-grid-pro";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { QueryDataGrid, QueryDataGridProps } from "src/common/grid/QueryDataGrid";
import { ContextMenuButton } from "src/common/menus/ContextMenuButton";
import { EventDateTimeText } from "src/common/time/DateTimeText";
import { EmailTemplateName } from "src/email/templates/EmailTemplateName";
import { useScheduledEmailContextMenu } from "src/email/scheduling/ScheduledEmailContextMenu";
import { useEvent } from "src/events/event-context";
import { ifHas } from "src/login/IfHas";
import { NameAndSubtitle } from "src/registrations/NameAndSubtitle";
import { ScheduledJobStatus } from "src/scheduling/ScheduledJobStatus";


export type ScheduledEmailsGridProps = Partial<QueryDataGridProps<ScheduledEmail>>

export const ScheduledEmailsGrid: FC<ScheduledEmailsGridProps> = ({
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!
    const emailsQuery = backend.scheduledEmails.useGetAll([client.id, event.id], {
        refetchInterval: 1000
    })

    return <QueryDataGrid
        queryOrRows={emailsQuery}
        columns={columns}
        disableColumnMenu
        {...props}
    />
}

const columns: GridColDef<ScheduledEmail>[] = [
    {
        field: "templateId",
        headerName: "Name",
        renderCell: ({ row }) => <NameAndSubtitle
            name={row.name}
            subtitle={<EmailTemplateName id={row.template.id} />}
        />,
        flex: 1,
    },
    {
        field: "scheduledTime",
        headerName: "Time",
        renderCell: ({ value }) => <EventDateTimeText dateTime={value} hideTimezone />,
        width: 200,
    },
    {
        field: "scheduledJob.state",
        headerName: "Status",
        renderCell: ({ row }) => <ScheduledJobStatus job={row.scheduledJob} />,
        width: 130,
    },
    {
        field: "actions",
        headerName: " ",
        renderCell: ({ row }) => <EmailContextMenuButton email={row} />,
        width: 50,
        sortable: false,
    }
]

type EmailContextMenuButtonProps = {
    email: ScheduledEmail
}

const EmailContextMenuButton: FC<EmailContextMenuButtonProps> = ({
    email
}) => {
    const client = useClient()!
    const event = useEvent()!
    const menu = useScheduledEmailContextMenu()

    return ifHas(Permission.SendEmails, client.id, event.id, <ContextMenuButton
        onClick={e => menu.open({
            email,
            anchorEl: e.currentTarget,
        })}
    />)
}