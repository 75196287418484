import { ConfiguredEmailTemplate, EventDetails, Scope } from "@marketpartner/backend-api";
import { DialogTitle, Stack } from "@mui/material";
import { FC, useState } from "react";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { ConfiguredTemplateSelector } from "src/email/templates/configured-templates/ConfiguredTemplateSelector";
import { EventSelector } from "src/events/EventSelector";


export type CopyConfiguredEmailTemplateDialogProps = LockableDialogProps & CopyConfiguredEmailTemplateDialogContentProps

const CopyConfiguredEmailTemplateDialog: FC<CopyConfiguredEmailTemplateDialogProps> = ({
    onCopyTemplate,
    ...props
}) => {
    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Copy email template</DialogTitle>
        <CopyConfiguredEmailTemplateDialogContent
            onCopyTemplate={onCopyTemplate}
        />
    </LockableDialog>
}

export type CopyConfiguredEmailTemplateDialogContentProps = {
    onCopyTemplate: (template: ConfiguredEmailTemplate) => void | Promise<unknown>
}

const CopyConfiguredEmailTemplateDialogContent: FC<CopyConfiguredEmailTemplateDialogContentProps> = ({
    onCopyTemplate
}) => {
    const [sourceEventId, setSourceEventId] = useState<string | null>(null)
    const [sourceEvent, setSourceEvent] = useState<EventDetails | null>(null)
    const [template, setTemplate] = useState<ConfiguredEmailTemplate | null>(null)

    const onChangeEventId = (eventId: string | null) => {
        setSourceEventId(eventId)
        setTemplate(null)
    }

    return <DialogForm
        actionName="Copy"
        formData={{}}
        onSubmit={() => Promise.resolve(onCopyTemplate(template!))}
        isValid={Boolean(template)}
    >
        <Stack spacing={2}>
            <EventSelector
                label="Source event"
                selectedId={sourceEventId}
                onSelectId={onChangeEventId}
                onSelectLoadedItem={setSourceEvent}
                required
            />
            {sourceEvent && <ConfiguredTemplateSelector
                event={sourceEvent}
                onSelectTemplate={setTemplate}
            />}
        </Stack>
    </DialogForm>
}

export const useCopyConfiguredEmailTemplateDialog = createPopupHook({
    element: CopyConfiguredEmailTemplateDialog,
    scope: Scope.Event,
})